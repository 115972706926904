import type { ComputedRef, Ref } from 'vue';
import type { Schemas } from '#shopware';
import type { AlgoliaProduct } from '~/types/models/algoliaProduct';
import type { AhProduct } from '~/types/models/product/product';
import { helpers } from '@vuelidate/validators';
import { translatedValidators } from '~/helpers/translatedValidators';

export type QuantityValidationProduct = {
    id: string;
    isCloseout: boolean;
    purchaseSteps: number | undefined;
    minPurchase: number | undefined;
    maxPurchase: number | undefined;
    name: string | undefined;
    productNumber: string | undefined;
    packUnit: number | undefined;
};

export type UseQuantityValidationReturn = {
    product: ComputedRef<QuantityValidationProduct>;
    sharedQuantity: Ref<number>;
    overlayVisible: ComputedRef<boolean>;
    toggleOverlay: () => void;
    minPurchaseIsInvalid: Ref<boolean>;
    isInvalid: Ref<boolean>;
    purchaseStepsIsInvalid: Ref<boolean>;
};

export function useQuantityValidation(
    currentProduct?: Partial<Schemas['Product']> | Partial<AhProduct> | Partial<AlgoliaProduct>,
    currentQuantity?: number,
) {
    const _sharedProduct: Ref<Partial<Schemas['Product']> | Partial<AhProduct | Partial<AlgoliaProduct>> | null> =
        inject('quantity-validation-product', ref(null));
    provide('quantity-validation-product', _sharedProduct);

    const _overlayVisible: Ref<boolean> = inject('quantity-validation-overlay', ref(false));
    provide('quantity-validation-overlay', _overlayVisible);

    const minPurchaseIsInvalid: Ref<boolean> = inject('quantity-validation-min-purchase-invalid', ref(false));
    provide('quantity-validation-min-purchase-invalid', minPurchaseIsInvalid);

    const maxPurchaseIsInvalid: Ref<boolean> = inject('quantity-validation-max-purchase-invalid', ref(false));
    provide('quantity-validation-max-purchase-invalid', maxPurchaseIsInvalid);

    const purchaseStepsIsInvalid: Ref<boolean> = inject('quantity-validation-purchase-steps-invalid', ref(false));
    provide('quantity-validation-purchase-steps-invalid', purchaseStepsIsInvalid);

    const isInvalid: Ref<boolean> = inject('quantity-validation-is-invalid', ref(false));
    provide('quantity-validation-is-invalid', isInvalid);

    const { availabilities } = useAvailability();
    const availability = computed(() => {
        if ((_sharedProduct.value as Partial<AhProduct>)?.extensions?.availability?.items?.length) {
            return (_sharedProduct.value as Partial<AhProduct>)?.extensions?.availability?.items;
        }

        if (!_sharedProduct.value?.productNumber) return [];
        return availabilities.value?.[_sharedProduct.value?.productNumber] ?? [];
    });
    const maxPurchase = computed(() => {
        if (availability.value?.length) {
            return availability.value[0].quantity;
        }

        return _sharedProduct.value?.maxPurchase || undefined;
    });

    const isAvailable = computed(() => {
        const status = availability.value?.[0]?.status || 9999999;
        const isCloseoutWithoutQuantity = _sharedProduct.value?.isCloseout && !maxPurchase.value;

        return ![5, 6].includes(status) && !isCloseoutWithoutQuantity;
    });

    if (currentProduct) _sharedProduct.value = currentProduct;

    const product = computed(() => {
        const quantityValidationProduct: QuantityValidationProduct = {
            id:
                (_sharedProduct.value as Partial<AhProduct>)?.id ||
                (_sharedProduct.value as AlgoliaProduct)?.productId ||
                '',
            isCloseout: _sharedProduct.value?.isCloseout || false,
            purchaseSteps: _sharedProduct.value?.purchaseSteps,
            minPurchase: _sharedProduct.value?.minPurchase,
            maxPurchase: _sharedProduct.value?.isCloseout ? maxPurchase.value : undefined,
            name:
                (_sharedProduct.value as Partial<AhProduct>)?.translated?.name ||
                (_sharedProduct.value as AlgoliaProduct)?.name,
            productNumber:
                (_sharedProduct.value as Partial<AhProduct>)?.productNumber ||
                (_sharedProduct.value as AlgoliaProduct)?.productNumber,
            packUnit:
                (_sharedProduct.value as Partial<AhProduct>)?.extensions?.attributes?.packUnit ||
                (_sharedProduct.value as AlgoliaProduct)?.packUnit,
        };

        return quantityValidationProduct;
    });
    const overlayVisible = computed(() => {
        return _overlayVisible.value;
    });

    const sharedQuantity: Ref<number> = inject(
        'quantity-validation-quantity',
        ref(
            currentQuantity
                ? currentQuantity
                : product.value && product.value.minPurchase && product.value.purchaseSteps
                  ? product.value.minPurchase > product.value.purchaseSteps
                      ? product.value.minPurchase
                      : product.value.purchaseSteps
                  : 1,
        ),
    );

    provide('quantity-validation-quantity', sharedQuantity);

    const toggleOverlay = () => {
        _overlayVisible.value = !_overlayVisible.value;
    };

    const { t } = useI18n();
    const { required, minValue, maxValue } = translatedValidators();
    const matchesPurchaseStep = (step: number) => {
        return helpers.withMessage(
            t('validations.purchaseSteps'),
            helpers.withParams({ step }, (value) => {
                // Ensure value is a number and matches the step condition
                if (typeof value !== 'number' || isNaN(value)) return false;
                return value % step === 0;
            }),
        );
    };

    const getQuantityRules = (
        minPurchase: number = 1,
        maxPurchase: number | undefined = undefined,
        purchaseSteps: number = 1,
    ) => {
        const rules: Record<string, any> = {
            required,
            minValue: minValue(minPurchase || 1),
            purchaseSteps: matchesPurchaseStep(purchaseSteps || 1),
        };

        if (maxPurchase) {
            rules.maxValue = maxValue(maxPurchase);
        }

        return rules;
    };

    return {
        product,
        isAvailable,
        sharedQuantity,
        overlayVisible,
        toggleOverlay,
        minPurchaseIsInvalid,
        maxPurchaseIsInvalid,
        isInvalid,
        purchaseStepsIsInvalid,
        getQuantityRules,
    };
}
